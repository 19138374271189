<div class="d-flex flex-lg-row flex-column" *ngIf="basket$ | async as basket">
  <div class="col orderDetailsLeftCol w-100">
    <div class="insideInfo">
      <p class="secondaryHeader leftAlign" *ngIf="basket.basket.deliverymode == 'pickup'">SELECT A TAKEOUT TIME</p>
      <p
        class="secondaryHeader leftAlign"
        *ngIf="basket.basket.deliverymode == 'delivery' || basket.basket.deliverymode == 'dispatch'"
      >
        SELECT A DELIVERY TIME
      </p>
      <p
        class="secondaryHeader leftAlign"
        *ngIf="basket.basket.deliverymode == 'delivery' || basket.basket.deliverymode == 'dinein'"
      >
        SELECT A DINE-IN TIME
      </p>

      <div class="d-flex flex-row">
        <div class="col-sm-12 orderDetailsRow m-0">
          <select
            *ngIf="(basket$ | async).timeSlots as timeSlots"
            class="form-select timeDropdown"
            (change)="onTimeChange($event.target.value)"
          >
            <option>Select Time</option>
            <option *ngFor="let availableTime of timeSlots; index as i" [value]="i">
              {{ availableTime.key }}
            </option>
            <option *ngIf="!timeSlots">Sorry, Sold Out</option>
          </select>
        </div>
      </div>
      <div class="d-flex flex-row" [@collapse]="!(basket.basket.deliverymode === 'dinein' && !!selectedTime)">
        <div class="col-12 text-start">
          <p>
            <span class="bold text-pp-orange">PLEASE NOTE:</span> Our pizzas take only
            <span class="bold">MINUTES</span> to make. Since our pizzas are <span class="bold">BEST</span> right out of
            the oven, we don’t fire your pizzas until <span class="bold">AFTER</span> you check in.
          </p>
          <ol>
            <li>
              Please arrive and check in at <strong class="bold">{{ selectedTime }}</strong> (or up to 10 minutes
              before).
            </li>
            <li>Look for the hanging “CHECK-IN” sign at the back of the restaurant (where we cut pizzas).</li>
            <li>
              Let us know you are a <strong class="bold">"Digital Dine-in” Customer</strong> and the name on your order.
            </li>
            <li>We’ll give you a table number and you can be seated.</li>
            <li>Your pizzas will be the next ones made – it only takes a few minutes!</li>
          </ol>
        </div>
      </div>
      <div class="lineBorder"></div>
      <p class="secondaryHeader leftAlign" *ngIf="basket.basket.deliverymode == 'pickup'">TAKEOUT ADDRESS</p>
      <p
        class="secondaryHeader leftAlign"
        *ngIf="basket.basket.deliverymode == 'delivery' || basket.basket.deliverymode == 'dispatch'"
      >
        DELIVERY ADDRESS
      </p>
      <p class="secondaryHeader leftAlign" *ngIf="basket.basket.deliverymode == 'dinein'">DINE-IN LOCATION</p>
      <div *ngIf="basket.basket.deliverymode == 'pickup' || 'dinein'" class="d-flex flex-row">
        <div class="col-lg-6 col-sm-12" *ngIf="(restaurant$ | async).restaurant as restaurant">
          <lib-location-info [location]="restaurant" [isOrderReview]="true"></lib-location-info>
          <!--          <div class="text-decoration-underline cursor-pointer" routerLink="/locations">Change Location</div>-->
        </div>
      </div>
      <div
        *ngIf="basket.basket.deliverymode == 'delivery' || basket.basket.deliverymode == 'dispatch'"
        class="d-flex flex-row"
      >
        <div *ngIf="basket.basket.deliveryaddress as address" class="col orderLocationInfo" style="text-align: left">
          <ng-container *ngIf="user$ | async as user">
            <ng-container *ngIf="user.info?.authtoken; else noAuth">
              <!-- Use user info -->
              <p class="bold m-0">{{ user.info.firstname }} {{ user.info.lastname }}</p>
            </ng-container>
            <ng-template #noAuth>
              <!-- Use guest info -->
              <p class="bold m-0">{{ basket.billingInfo.firstname }} {{ basket.billingInfo.lastname }}</p>
            </ng-template>
          </ng-container>
          <p class="m-0">{{ address.streetaddress }}</p>
          <p class="m-0">{{ address.city }}, {{ address.zipcode }}</p>
        </div>
        <ng-container *ngIf="deliveryInfoForm">
          <form style="width: 100%" [formGroup]="deliveryInfoForm" (ngSubmit)="submitForm()">
            <textarea
              formControlName="specialInstructions"
              aria-label="Delivery Instructions"
              placeholder="Delivery Instructions"
              class="form-control specialInstructionsText"
              id="specialInstructions"
              rows="3"
            ></textarea>
          </form>
        </ng-container>
      </div>
      <div class="lineBorder"></div>

      <div *ngIf="basket.basket.deliverymode == 'dinein'">
        <p class="secondaryHeader leftAlign">FIRST NAME, LAST NAME</p>
        <div class="d-flex flex-row gap-3 dinein-name">
          <div class="col">
            <lib-single-text-field label="First Name" inputType="text" controlName="firstName" [parentForm]="guestForm">
            </lib-single-text-field>
          </div>
          <div class="col">
            <lib-single-text-field label="Last Name" inputType="text" controlName="lastName" [parentForm]="guestForm">
            </lib-single-text-field>
          </div>
        </div>
        <div class="lineBorder"></div>
        <p class="secondaryHeader leftAlign">PARTY SIZE</p>
        <div class="d-flex flex-row">
          <div class="col text-start">
            <lib-party-size
              [maxTableSize]="maxTableSize"
              [selectedTableSize]="selectedTableSize"
              (tableSizeEmit)="tableSizeSelected($event)"
            >
            </lib-party-size>
          </div>
        </div>
        <div class="lineBorder"></div>
      </div>

      <ng-container *ngIf="user$ | async as user">
        <ng-container *ngIf="user.info?.authtoken">
          <div class="orderDetailsRow leftAlign">
            <!-- TODO: Implement saving address/delivery instructions -->
            <!-- <input type="checkbox" /><span style="margin: 0px 30px;">Save Address and Special Instructions</span> -->
          </div>
        </ng-container>
      </ng-container>

      <div class="mobileBagItemNoInline">
        <!-- <p class="secondaryHeader leftAlign">SAVE ORDER</p>
        <div class="lineBorder"></div> -->
        <p class="secondaryHeader leftAlign leftSideItemsBag">YOUR ORDER</p>
        <div class="lineBorder leftSideDivider"></div>
        <div class="d-flex flex-row">
          <div class="col-12 noPaddingCol leftSideItemsBag" style="margin-top: 20px;">
            <div>
              <lib-bag-item *ngFor="let product of basket.basket.products" [product]="product" isCheckoutBag="true">
              </lib-bag-item>
            </div>
          </div>
        </div>

        <div class="orderDetailsRow leftAlign" style="margin-top: 20px;">
          <ng-container *ngIf="user$ | async as userState">
            <ng-container *ngIf="userState.userPreferences && userState.userPreferences.optin === 'false'">
              <input type="checkbox" [formControl]="emailOptIn"/><span
                style="margin: 0px 20px; vertical-align: text-bottom;"
                >Send me special deals via email!</span
              >
              <br />
              <small class="text-emphasis" style="margin: 0 20px 0 32px"
                ><em
                  >We will never share your contact information or use for anything else. You can unsubscribe at any
                  time.</em
                ></small
              >
              <br />
              <br
            /></ng-container>
          </ng-container>
          <input type="checkbox" (click)="openOrderName(orderNameModal)" [checked]="isChecked" #saveFavorite /><span
            style="margin: 0px 20px; vertical-align: text-bottom;"
            >Save This Order as a Favorite</span
          >
          <ng-container *ngIf="withAnotherOrderCustomField">
            <br />
            <input type="checkbox" [formControl]="withAnotherOrder" /><span
              style="margin: 0px 20px; vertical-align: text-bottom;"
              >This order should be combined with another order I've placed</span
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-md-12 orderDetailsRightCol">
    <div>
      <app-checkout-bag
        [basket]="basket.basket"
        isPayment="true"
        (nextPageEvent)="continueToNextPage()"
        [buttonAvailable]="checkAvailability()"
        [isLoading]="isLoading"
      >
      </app-checkout-bag>
    </div>
  </div>
</div>

<ng-template #orderNameModal let-modal>
  <lib-favorite-order-name
    (xClicked)="modal.dismiss('Cross click')"
    (saveOrderFavorite)="saveOrderAsFavorite($event, modal)"
  >
  </lib-favorite-order-name>
</ng-template>

<ng-template #upsellModal let-modal>
  <app-upsell-modal [groups]="this.upsellGroups" (xClicked)="modal.dismiss()"></app-upsell-modal>
</ng-template>
