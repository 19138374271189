<div class="container-fluider">
  <div *ngIf="restaurants | async as locations" style="padding-top: env(safe-area-inset-top, 0)">
    <lib-locations
      [locations]="locations"
      [cmsLocations]="cmsLocations$ | async"
      (passOrderHeaderEvent)="goToMenu($event)"
      [selectedLocation]="selectedLocation"
    ></lib-locations>
  </div>
</div>

<ng-template #message let-messageModal>
  <lib-scheduled-message-modal
    [message]="activeMessage"
    (xClicked)="messageModal.dismiss()"
  ></lib-scheduled-message-modal>
</ng-template>

<ng-template #transferModal let-modal>
  <div class="modal-wrapper">
    <i class="fas fa-times x-close" (click)="modal.close()"></i>
    <h1 class="text-center text-uppercase">Order Started</h1>
    <div class="text-center pb-3">
      We cannot move your items to a new location. Switching locations will empty your current basket.
    </div>
    <div class="d-flex flex-row gap-2 w-100">
      <lib-button
        class="flex-grow-1 flex-basis-0 w-100"
        (buttonClick)="modal.close()"
        text="Cancel"
        [ghost]="true"
        [large]="true"
      ></lib-button>
      <lib-button
        class="flex-grow-1 flex-basis-0 w-100"
        (buttonClick)="transferConfirm()"
        [large]="true"
        text="Switch Locations"
      ></lib-button>
    </div>
  </div>
</ng-template>
